// Gatsby supports TypeScript natively!
import React from 'react'
import { Typography } from '@material-ui/core'
import Layout from '../components/gatsby/layout'
import SEO from '../components/gatsby/seo'

const Stages = props => {
    return (
        <Layout>
            <SEO title="Stages" description="On this page you see all stages" />
            <Typography variant="h4" align="center" component="h1">
                Stages
            </Typography>
            <ul>
                <li>
                    <Typography variant="body1">Amor (only onced)</Typography>
                </li>
                <li>
                    <Typography variant="body1">Werewolf</Typography>
                </li>
                <li>
                    <Typography variant="body1">
                        Witch / Seer / Priest / Dream girl
                    </Typography>
                </li>
                <li>
                    <Typography variant="body1">Nomination</Typography>
                </li>
                <li>
                    <Typography variant="body1">Voting</Typography>
                </li>
            </ul>
        </Layout>
    )
}

export default Stages
